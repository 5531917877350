<template>
  <!-- 店铺管理 -->
  <div class="shopManage">
    <div class="shopManage__left box-shadow">
      <el-tabs v-model="tabs" type="card">
        <el-tab-pane label="切换店铺" name="2"></el-tab-pane>
        <el-tab-pane v-if="shopData != null && ztype === 1" label="货品分类" name="1"></el-tab-pane>
      </el-tabs>

      <div v-if="tabs === '1'" style="height: 100%" class="scrollbar">
        <div class="padding10"><el-input v-model="filterText" class="wHP" placeholder="分类名称" clearable> </el-input></div>
        <div style="height: 100%">
          <el-tree ref="goodTree" show-checkbox class="filter-tree" :data="data" node-key="categoryId" :props="defaultProps" default-expand-all :filter-node-method="filterNode" />
        </div>
      </div>

      <div v-if="tabs === '2'" style="height: 100%" class="scrollbar">
        <div class="padding10"><el-input v-model="filterShopText" class="wHP" placeholder="门店名称/编号" clearable> </el-input></div>
        <div style="height: 100%">
          <el-tabs v-model="shopId" tab-position="left" @tab-click="handleClick">
            <el-tab-pane v-for="tab in shopList" :key="tab.sid" :label="tab.cname" :name="tab.sid" style="width: 30%" />
          </el-tabs>
        </div>
      </div>
    </div>

    <div class="shopManage__right box-shadow">
      <el-row v-if="shopData != null" class="padding10">
        <el-col>
          <i class="el-icon-menu" />
          <span v-if="shopData.ztype === 0" class="fs15 fWB">[美团闪购] {{ shopData.cname }}</span>
          <span v-if="shopData.ztype === 1" class="fs15 fWB">[饿了么零售] {{ shopData.cname }}</span>
          <span v-if="shopData.ztype === 2" class="fs15 fWB">[京东到家] {{ shopData.cname }}</span>
          <span class="fs13"> {{ shopData.rplatShop }} </span>
          <el-tag size="mini" class="borderRadius50 marginLeft10">营业中</el-tag>
          <el-tag size="mini" class="borderRadius50 marginLeft10">自动分单</el-tag>
        </el-col>
        <el-col>所在区域：福建省 - 福州市 - 闽侯县</el-col>
      </el-row>
      <div class="bg-purple-light bcWhite margin10 h700">
        <el-tabs v-model="tabs1" type="card" @tab-click="tabs1Click">
          <el-tab-pane label="货品列表" name="1"></el-tab-pane>
          <el-tab-pane label="店铺订单" name="2"></el-tab-pane>
        </el-tabs>
        <el-row class="padding10">
          <el-col :span="20" class="flex">
            <el-select v-if="tabs1 === '1' && ztype === 1" v-model="stateValue" class="marginRight10" placeholder="全部状态">
              <el-option v-for="item in stateOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
            <el-input v-if="tabs1 === '1' && ztype === 2" v-model="cName" placeholder="商品名称" class="w300" clearable />
            <el-input v-if="tabs1 === '2'" v-model="orderQueryPara.orderCode" placeholder="订单号/手机号" class="w300" clearable />
            <el-date-picker v-if="tabs1 === '2'" v-model="orderQueryPara.startTime" type="date" placeholder="开始时间" style="margin-left: 1%"> </el-date-picker>
            <el-date-picker v-if="tabs1 === '2'" v-model="orderQueryPara.endTime" type="date" placeholder="结束时间" style="margin-left: 1%"> </el-date-picker>
            <el-button v-if="tabs1 === '2'" style="margin-left: 5%" type="primary" @click="getMonth(1)">本月</el-button>
            <el-button v-if="tabs1 === '2'" type="primary" @click="getMonth(2)">上个月</el-button>
            <el-button v-if="tabs1 === '2'" type="primary" @click="getMonth(3)">三个月</el-button>
            <el-button v-if="ztype === 1" type="primary" @click="doSearch">查询</el-button>
          </el-col>
          <el-col v-if="tabs1 === '1'" :span="12" class="textRight" style="margin-left: 50%; margin-top: -3.5%">
            <el-button v-if="ztype === 1" type="primary" @click="doSearch">查询</el-button>
            <el-radio-group v-if="tabs1 === '1'" v-model="selectedOption" style="margin-left: auto">
              <el-radio-button label="1"> <i class="el-icon-s-order"></i> </el-radio-button>
              <el-radio-button label="2"> <i class="el-icon-s-grid"></i> </el-radio-button>
            </el-radio-group>
          </el-col>
          <el-col v-if="tabs1 === '1' && selectedOption === '1'" class="marginTop10">
            <el-table :data="list">
              <el-table-column label="序号" align="center" type="index" width="30" />
              <el-table-column prop="ccode" label="商品编码" />
              <el-table-column prop="cname" label="商品名称" width="400" />
              <el-table-column prop="zunit" label="单位" />
              <el-table-column prop="nprice" label="零售价" />
              <el-table-column prop="cname" label="库存" />
              <el-table-column align="center" label="状态">
                <template slot-scope="scope">
                  <div v-if="scope.row.bstatus === true" slot="reference" class="name-wrapper">上架</div>
                  <div v-if="scope.row.bstatus != true" slot="reference" class="name-wrapper">下架</div>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" fixed="right">
                <template slot-scope="scope">
                  <el-button type="primary" size="small" @click="handleEditGoods(scope.row.csku)">编辑</el-button>
                  <el-button v-if="scope.row.bstatus === true" type="primary" style="width: 50%" @click="goodsSellStatus(scope.row.csku, shopId, 0)">上架</el-button>
                  <el-button v-if="scope.row.bstatus != true" type="danger" style="width: 50%" @click="goodsSellStatus(scope.row.csku, shopId, 1)">下架</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col v-if="tabs1 === '1' && selectedOption === '2'" class="marginTop10" style="height: 600px;overflow: auto;">
            <el-row>
              <el-col v-for="(goods, index) in list" :key="index" :span="8" style="padding: 0px 5px;">
                <div class="marginBottom10">
                  <el-card :body-style="{ padding: '0' }">
                    <el-row :gutter="10" class="margin10">
                      <el-col :span="5">
                        <div class="bg-purple" style="margin-top: 5px">
                          <el-image :src="goods.cmasterUrl" fit="fit"></el-image>
                        </div>
                        <div class="textCenter">零售价</div>
                        <div class="textCenter">{{ goods.nprice }}</div>
                      </el-col>
                      <el-col :span="19">
                        <div class="bg-purple-light">
                          <el-link type="primary" style="display: -webkit-box; -webkit-box-orient: vertical; line-clamp: 2; -webkit-line-clamp: 2; overflow: hidden; text-overflow: ellipsis;">{{ goods.cname }}</el-link>
                          <div>商品大类 - 商品中类 - 商品小类</div>
                          <el-row>
                            <el-col>货品SKU: {{ goods.csku }}</el-col>
                          </el-row>
                          <el-row>
                            <el-col>货品条码: {{ goods.cmodel }}</el-col>
                          </el-row>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col>
                        <el-button-group style="width: 100%">
                          <el-button style="width: 50%" @click="handleEditGoods(goods.csku)">编辑</el-button>
                          <el-button v-if="goods.bstatus === true" style="width: 50%" @click="goodsSellStatus(goods.csku, shopId, 0)">上架</el-button>
                          <el-button v-if="goods.bstatus != true" style="width: 50%" @click="goodsSellStatus(goods.csku, shopId, 1)">下架</el-button>
                        </el-button-group>
                      </el-col>
                    </el-row>
                  </el-card>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <div v-if="tabs1 === '1'" class="block">
            <el-pagination :current-page.sync="queryPara.currentPage" :page-sizes="[9, 18, 36, 72]" :page-size="queryPara.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="queryPara.total" @size-change="handleSizeChange" @current-change="handleCurrentChange"> </el-pagination>
          </div>
          <el-col v-if="tabs1 === '2'" class="marginTop10">
            <el-table :data="orderList">
              <el-table-column label="序号" align="center" type="index" width="30" />
              <el-table-column prop="ccode" align="center" label="订单号" width="300" />
              <el-table-column prop="cshippingPhone" label="手机号码" />
              <el-table-column prop="cgoodsNum" label="商品数量" />
              <el-table-column prop="nrealPrice" label="订单金额" />
              <el-table-column prop="ndeliveryPrice" label="配送费" />
              <el-table-column prop="norderRevenue" label="实收金额" />
              <el-table-column align="center" label="状态">
                <template slot-scope="scope">
                  <div v-if="scope.row.zflow === 0" slot="reference" class="name-wrapper">已完成</div>
                  <div v-if="scope.row.zflow === 1" slot="reference" class="name-wrapper">新订单</div>
                  <div v-if="scope.row.zflow === 2" slot="reference" class="name-wrapper">异常订单</div>
                  <div v-if="scope.row.zflow === 3" slot="reference" class="name-wrapper">催单</div>
                  <div v-if="scope.row.zflow === 4" slot="reference" class="name-wrapper">配送异常</div>
                  <div v-if="scope.row.zflow === 5" slot="reference" class="name-wrapper">取消/退款</div>
                  <div v-if="scope.row.zflow === 6" slot="reference" class="name-wrapper">待配送</div>
                  <div v-if="scope.row.zflow === 7" slot="reference" class="name-wrapper">待抢单</div>
                  <div v-if="scope.row.zflow === 8" slot="reference" class="name-wrapper">待取货</div>
                  <div v-if="scope.row.zflow === 9" slot="reference" class="name-wrapper">配送中</div>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <div v-if="tabs1 === '2'" class="block">
            <el-pagination :current-page.sync="orderQueryPara.currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="orderQueryPara.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="orderQueryPara.total" @size-change="orderSizeChange" @current-change="orderCurrentChange"> </el-pagination>
          </div>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { queryList, getShopById, queryElemeGoodsSorts, queryMeituanGoodsList, queryElemeGoodsList, queryJdGoodsList, queryOrderPageList, goodsSellStatus } from "../../api/shop/shop";
import moment from "moment";
export default {
  name: "ShopManage",

  props: {},
  data() {
    return {
      tabs: "2",
      tabs1: "1",
      queryPara: {
        currentPage: 1,
        pageSize: 9,
        total: 0,
      },
      queryShopPara: {
        currentPage: 1,
        pageSize: 10,
      },
      shopId: null,
      selectedOption: "1",
      filterText: "",
      cName: "",
      orderCode: "",
      filterShopText: "",
      shopData: null,
      orderList: [],
      shopList: [],
      orderQueryPara: {
        currentPage: 1,
        pageSize: 10,
        startTime: "",
        endTime: "",
        total: 0,
      },
      stateOptions: [
        { value: null, label: "全部状态" },
        { value: "1", label: "上架" },
        { value: "2", label: "下架" },
      ],
      list: [],
      urlApi: "",
      orderUrlApi: "",
      ztype: -1,
      stateValue: null,
      regionOptions: [
        { value: "0", label: "全部渠道" },
        { value: "1", label: "美团闪购" },
        { value: "2", label: "京东到家" },
        { value: "3", label: "饿了吗" },
      ],
      regionValue: "0",
      data: [],
      defaultProps: {
        children: "children",
        name: "name",
        categoryId: "category_id",
      },
    };
  },
  watch: {
    filterText(val) {
      this.$refs.goodTree.filter(val);
    },
    filterShopText(val) {
      this.queryShopPara.cName = val;
      queryList(this.queryShopPara).then((response) => {
        this.shopList = response.data;
      });
    },
  },
  created() {
    queryList(this.queryShopPara).then((response) => {
      this.shopList = response.data;
      if (response.data.length > 0) {
        getShopById(response.data[0].sid).then((response) => {
          this.shopData = response.data;
          this.ztype = this.shopData.ztype;
          this.doSearch();
          if (this.ztype === 1) {
            queryElemeGoodsSorts(this.shopData.rplatShop).then((response) => {
              this.data = response.data;
            });
          }
        });
      }
    });
  },
  mounted() {},
  methods: {
    goodsSellStatus(sku, shopId, sellStatus) {
      this.$confirm("是否操作该商品?")
        .then(function () {
          let data = { goodsSku: sku, sellStatus: sellStatus, shopId: shopId };
          return goodsSellStatus(data);
        })
        .then(() => {
          this.doSearch();
          this.$message("操作成功");
        })
        .catch(() => {});
    },
    tabs1Click(tab, event) {
      if (this.tabs1 === "2") {
        this.tabs = "2";
      }
      this.doSearch();
    },
    handleEditGoods(sid) {
      this.$router.push({ path: "/goods/goodsInfo", query: { sid: sid } });
    },
    handleSizeChange(val) {
      this.queryPara.pageSize = val;
      this.doSearch();
    },
    handleCurrentChange(val) {
      this.queryPara.currentPage = val;
      this.doSearch();
    },
    orderSizeChange(val) {
      this.orderQueryPara.pageSize = val;
      this.doSearch();
    },
    orderCurrentChange(val) {
      this.orderQueryPara.currentPage = val;
      this.doSearch();
    },
    handleClick(tab, event) {
      getShopById(this.shopId).then((response) => {
        this.shopData = response.data;
        this.ztype = this.shopData.ztype;
        this.doSearch();
        if (this.ztype === 1) {
          queryElemeGoodsSorts(this.shopData.rplatShop).then((response) => {
            this.data = response.data;
          });
        }
      });
    },
    getMonth(value) {
      if (value === 1) {
        this.orderQueryPara.startTime = moment().startOf("months").format("YYYY-MM-DD");
        this.orderQueryPara.endTime = moment().endOf("months").format("YYYY-MM-DD");
      } else if (value === 2) {
        this.orderQueryPara.startTime = moment().subtract(1, "months").startOf("months").format("YYYY-MM-DD");
        this.orderQueryPara.endTime = moment().subtract(1, "months").endOf("months").format("YYYY-MM-DD");
      } else if (value === 3) {
        this.orderQueryPara.startTime = moment().subtract(3, "months").format("YYYY-MM-DD");
        this.orderQueryPara.endTime = moment().format("YYYY-MM-DD");
      }
      this.doSearch();
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    doSearch() {
      if (this.tabs1 === "1") {
        if (this.ztype === 0) {
          this.queryPara.shopId = this.shopData.rplatShop;
          queryMeituanGoodsList(this.queryPara).then((response) => {
            this.list = response.data.list;
            this.queryPara.total = response.data.total;
          });
        }
        if (this.ztype === 1) {
          if (this.data.length > 0) {
            const checkedNodes = this.$refs.goodTree.getCheckedKeys();
            this.queryPara.customCatId = checkedNodes + "";
          }
          this.queryPara.shopId = this.shopData.rplatShop;
          this.queryPara.status = this.stateValue;
          queryElemeGoodsList(this.queryPara).then((response) => {
            this.list = response.data.list;
            this.queryPara.total = response.data.total;
          });
        }
        if (this.ztype === 2) {
          this.queryPara.cName = this.cName;
          queryJdGoodsList(this.queryPara).then((response) => {
            this.list = response.data.list;
            this.queryPara.total = response.data.total;
          });
        }
      } else if (this.tabs1 === "2") {
        this.orderQueryPara.shopId = this.shopData.sid;
        queryOrderPageList(this.orderQueryPara).then((response) => {
          this.orderList = response.data.list;
          this.orderQueryPara.total = response.data.total;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.shopManage {
  display: flex;
  flex-direction: row;
  height: 100%;
  flex: 1;
  overflow: hidden;
  &__left {
    width: 15%;
    background-color: white;
    margin: 3px;
    margin-right: 15px;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  &__right {
    padding: 10px;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 3px;
    background-color: white;
  }
}
</style>

<style lang="scss">
// .el-tabs__nav {
//   //width: 100%;
// }
</style>
