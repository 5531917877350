import request from "../../utils/request";

/**店铺删除
 *
 * @param sid
 * @returns {*}
 */
export function deleteshopById(sid) {
  return request({
    url: "/api/repository/stoShop/delete/" + sid,
    method: "delete",
  });
}

/**店铺修改
 *
 * @param sid
 * @returns {*}
 */
export function updateShopById(data) {
  return request({
    url: "/api/repository/stoShop/update",
    method: "post",
    data: data,
  });
}

export function queryList(params) {
  return request({
    url: "/api/repository/stoShop/queryList/",
    method: "get",
    params: params,
  });
}

export function queryAreaList() {
  return request({
    url: "/api/repository/stoShop/queryAreaList",
    method: "get",
  });
}

export function queryMerchantList() {
  return request({
    url: "/api/repository/merchant/queryList",
    method: "get",
  });
}
export function getShopById(id) {
  return request({
    url: "/api/repository/stoShop/queryById/" + id,
    method: "get",
  });
}
export function queryElemeGoodsSorts(shopId) {
  return request({
    url: "/api/repository/repGoodsSorts/queryElemeGoodsSorts/" + shopId,
    method: "get",
  });
}
export function queryMeituanGoodsList(params) {
  return request({
    url: "/api/repository/repGoods/queryMeituanGoodsList",
    method: "get",
    params: params,
  });
}
export function queryElemeGoodsList(params) {
  return request({
    url: "/api/repository/repGoods/queryElemeGoodsList",
    method: "get",
    params: params,
  });
}
export function queryJdGoodsList(params) {
  return request({
    url: "/api/repository/repGoods/queryJdGoodsList",
    method: "get",
    params: params,
  });
}

export function queryOrderPageList(params) {
  return request({
    url: "/api/repository/ordMain/shopOrdMainList",
    method: "get",
    params: params,
  });
}
export function goodsSellStatus(data) {
  return request({
    url: "/api/repository/repGoods/goodsSellStatus",
    method: "post",
    data: data,
  });
}
